export const useI18nPageInfo = () => {
  const i18nParams = useState<LocalizedRouteParams | undefined>('i18n_params')
  const route = useRoute()

  const initSEO = async ({ sysId = 'none' }) => {
    let routeParams = {}
    try {
      // Note: we cannot pass the full `route` object as param to api endpoint because it breaks when running on client side (circular JSON exception)
      routeParams = await $fetch(`/api/v3/core_routes/i18n_params/${sysId}`, {
        method: 'POST',
        body: {
          route: {
            path: route.path,
            params: route.params
          }
        }
      })
      i18nParams.value = routeParams
    } catch (e) {
      console.log(`[initSEO] [ERROR] Cannot retrive i18n route params for page ${route.path} [sysId: ${sysId}][ERROR=${e}]`)
      i18nParams.value = {}
    }
    return routeParams
  }

  return {
    initSEO,
    i18nParams
  }
}
